import { useState, useEffect } from "react";
import { useParams, useLocation, useNavigate, } from "react-router-dom";
import { cancelDelivery, getAddressDetails } from '../utils/Api';
import AddPayment from "./AddPayment";
import './Payment.css';
import Document from "./Document";
import { useAuth } from './hook/useAuth';
import ContractInfo from "./ContractInfo";
import ConfirmDelivery from "./ConfirmDelivery";
import CancelDelivery from "./CancelDelivery";
import NavLink from "./NavLink";
import ClientPhones from "./ClientPhones";
import ManagerPhones from "./ManagerPhones";
import Returns from "./Returns";


function Payment(props) {
    let params = useParams();
    let location = useLocation();
    let navigate = useNavigate();

    const [enablePayment, setEnablePayment] = useState(false);
    const [showPayment, setShowPayment] = useState(false);
    const [showConfirmDelivery, setShowConfirmDelivery] = useState(false);
    const [showCancelDelivery, setShowCancelDelivery] = useState(false);

    const [addressDetails, setAddressDetails] = useState({});
    const authCtx = useAuth();

    useEffect(() => {
        if (authCtx && authCtx.isLoggedIn) {
            getAddressDetails(authCtx.token, params.id)
                .then(data => {
                    setAddressDetails(data);
                    setEnablePayment(data.summByDocuments > 0);
                });
        }

    }, [params, authCtx])

    function toggleActions(sPayment, sConfirm, sCancel){
        if(sPayment){
            setShowPayment(true);
            setShowConfirmDelivery(false);
            setShowCancelDelivery(false);
        }else if(sConfirm){
            setShowPayment(false);
            setShowConfirmDelivery(true);
            setShowCancelDelivery(false);
            
        }else if(sCancel){
            setShowPayment(false);
            setShowConfirmDelivery(false);
            setShowCancelDelivery(true);
        }else{
            setShowPayment(false);
            setShowConfirmDelivery(false);
            setShowCancelDelivery(false);
        }

        window.scrollTo(0, window.outerHeight);

    }

    function handleReturnChangeStatus(status){
        setAddressDetails(prevState =>{
            prevState.returns.forEach(item =>{
                item.deliveryStatus = status;
            });

            return {...prevState};
        });        
    }

    return (
        <>
            <h2><button className="payment__back-page" onClick={() => { navigate('/') }}> &lt; </button> Оплата</h2>
            <div className="payment__contractor">{addressDetails.contractor}</div>
            <div className="payment__address">{addressDetails.address}</div>
            {addressDetails.latitude && <NavLink longitude={addressDetails.longitude} latitude={addressDetails.latitude} /> }
            
            <div className="payment__address-comment">
                <ClientPhones strPhones={addressDetails.addressComment}></ClientPhones>
            </div>

            {addressDetails.returns && addressDetails.returns.length > 0 && <Returns documents={addressDetails.returns} onStatusChange={handleReturnChangeStatus}></Returns>}

            <div className="payment__documents">
                <h3>Отдать заявки</h3>
                {addressDetails.documents && addressDetails.documents.map((document) => {
                    return (<Document key={document.id} {...document}></Document>);
                })}             

                <div className='payment__amoumt-by-documents'>Итого по документам : <span className='payment__sum-by-documents'>{addressDetails.summByDocuments}</span></div>
            </div>

            <div>
                <h3>Задолженность по договорам :</h3>
                {addressDetails.contractsDebt && addressDetails.contractsDebt.map((contract) =>{
                    return(<ContractInfo key={contract.name} {...contract}></ContractInfo>);
                }) }
            </div>

            <ManagerPhones manager={addressDetails.manager}></ManagerPhones>

            <div className="payment__actions">
                { enablePayment && <button className="payment__action-button payment__action-button_payment" onClick={()=>{toggleActions(true, false, false)}}>Оплата</button>}
                <button className="payment__action-button payment__action-button_confirm" onClick={()=>{toggleActions(false, true, false)}}>Подтверждение</button>
                <button className="payment__action-button payment__action-button_cancel" onClick={()=>{toggleActions(false, false, true)}}>Отмена</button>
            </div>

            { showPayment && <AddPayment 
                                    contractorId={addressDetails.contractorId} 
                                    routeId={addressDetails.routeId} 
                                    documents={addressDetails.documents} 
                                    addressId={addressDetails.addressId} 
                                    handleDeliveryAddress={props.handleDeliveryAddress} 
                                    transactionId={addressDetails.transactionId}>
                                </AddPayment>
            }
            {showConfirmDelivery && <ConfirmDelivery 
                                    documents={addressDetails.documents} 
                                    addressId={addressDetails.addressId} 
                                    handleDeliveryAddress={props.handleDeliveryAddress} 
                                    transactionId={addressDetails.transactionId}>
                                </ConfirmDelivery>
            }

            {showCancelDelivery &&  <CancelDelivery 
                documents={addressDetails.documents} 
                addressId={addressDetails.addressId} 
                handleDeliveryAddress={props.handleDeliveryAddress} 
                transactionId={addressDetails.transactionId}>
            </CancelDelivery>}
        </>
    )
}

export default Payment;