import {useState} from 'react';
import './ClientPhones.css';

function ClientPhones({ strPhones }) {
    const [showPhones, setShowPhones] = useState(false);

    function parsePhones(str) {
        let phones = [];

        if(!str){
            return phones;
        }

        const regex = /(\+\d{11})\w+/gim;
        let formatPhones = str.match(regex);
        if (Array.isArray(formatPhones)) {
            formatPhones.forEach(item => {
                phones.push(item);
            });
        }

        // Телефон в формате +375 (33) 999-99-99
        const regex2 = /\+\d{3} \(\d{2}\) \d{3}-\d{2}-\d{2}/gim
        if (regex2.test(str)) {
            let nonFormatPhones = str.match(regex2);
            for (let i = 0; i < nonFormatPhones.length; i++) {
                let nonFormatPhone = nonFormatPhones[i];
                nonFormatPhone = nonFormatPhone.replace(/\(| |\)|\-/gim, '');
                phones.push(nonFormatPhone);
            }
        }

        // Телефон в формате +375 29 9999999
        const regex3 = /\+\d{3} \d{2} \d{3}\d{2}\d{2}/gim
        if (regex3.test(str)) {
            let nonFormatPhones = str.match(regex3);
            for (let i = 0; i < nonFormatPhones.length; i++) {
                let nonFormatPhone = nonFormatPhones[i];
                nonFormatPhone = nonFormatPhone.replace(/\(| |\)|\-/gim, '');
                phones.push(nonFormatPhone);
            }
        }

         // Телефон в формате +375 29 999 99 99
        const regex4 = /\+\d{3} \d{2} \d{3} \d{2} \d{2}/gim
        if (regex4.test(str)) {
        let nonFormatPhones = str.match(regex4);
        for (let i = 0; i < nonFormatPhones.length; i++) {
            let nonFormatPhone = nonFormatPhones[i];
            nonFormatPhone = nonFormatPhone.replace(/\(| |\)|\-/gim, '');
            phones.push(nonFormatPhone);
        }
    }

        return phones;
    }

    const phones = parsePhones(strPhones);

    return (
        <>
            <div onClick={()=>{ setShowPhones( (prevValue) =>!prevValue)}}>{strPhones}</div>
            <ul className='client-phones__list'>
                {showPhones && phones.map((phone)=>{
                    return (<li className='client-phones__list-item' key={phone}><a href={`tel:${phone}`}><span className='client-phones__phone-icon'></span>{phone}</a></li>)
                })}
            </ul>
        </>
    )

}

export default ClientPhones;