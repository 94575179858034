import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from './hook/useAuth';
import { useState } from 'react';
import { login } from '../utils/Api';
import './Login.css';
import Button from "./Button";
import InfoTooltip from "./InfoTooltip";

function Login() {
    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');

    const [showInfo, setShowInfo] = useState(false);
    const [message, setMessage] = useState({ isError: false, text: '' });

    const navigate = useNavigate();
    const location = useLocation();

    const { signin } = useAuth();

    const fromPage = location.state?.from?.pathname || '/';

    const handleUserNameChange = (evt) => {
        setUserName(evt.target.value);
    }

    const handlePasswordChange = (evt) => {
        setPassword(evt.target.value);
    }

    function handleCloseInfoTooltip() {
        setShowInfo(false);
    }

    const handleSubmit = (evt) => {
        evt.preventDefault();

        login(userName, password)
            .then(data => {            
                signin(userName, data.jwt, () => { navigate(fromPage, { replace: true }) });
            })
            .catch(err =>{
                // console.log(err);
                setMessage({ isError: true, text: err.toString() });
                setShowInfo(true);
            });
    }

    return (
        <>
            <h2>Вход</h2>
            
            <form onSubmit={handleSubmit} className="login">
                <label>Введите логин : </label>
                <input className="login__input" name="username" value={userName} placeholder="Логин" onChange={handleUserNameChange} />
                
                <label>Введите пароль : </label>
                <input name="password" type="password" autoComplete="on" className="login__input"  value={password} placeholder="Пароль" onChange={handlePasswordChange} />

                <Button text='Войти'></Button>
            </form>
            {showInfo && <InfoTooltip message={message} onClose={handleCloseInfoTooltip} />}
        </>
    )

}

export default Login;