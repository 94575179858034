
//let url = 'https://apidelivery.sviat.by';
let url = 'https://apidelivery.sviat.by'; 
//let url = 'http://sviat.by:3005';
if (window.location.host === 'sviat.by:3000') {
  url = 'http://sviat.by:3005';
} else if (window.location.host === 'delivery.sviat.by') {
  url = 'https://apidelivery.sviat.by';
} else if (window.location.host === 'localhost:3000') {
  //url = 'http://localhost:3005';
}



let userJWT = '';

async function checkResponse(res) {
  if (res.ok) {
    return res.json();
  } else {
    let body = await res.text();

    let textError = '';
    try {
      textError = JSON.parse(body).message;
    } catch (e) {
      textError = body;
    }

    return Promise.reject(textError);
  }
}

function getRoute(userJWT) {
  return fetch(`${url}/task`, {
    method: 'get',
    headers: {
      "Content-Type": "application/json",
      "Authorization": `${userJWT}`,
    },
  }).then(checkResponse);
}

function getAddressDetails(userJWT, addressId) {
  return fetch(`${url}/task/detail/${addressId}`, {
    method: 'get',
    headers: {
      "Content-Type": "application/json",
      "Authorization": `${userJWT}`,
    },
  }).then(checkResponse);
}

function login(user, password) {
  return fetch(`${url}/users/login`, {
    method: 'POST',
    body: JSON.stringify({
      login: user,
      password: password,
    }),
    headers: {
      "Content-Type": "application/json",
      "Authorization": `${userJWT}`,
    },
  }).then(checkResponse);
}

function addPayment(userJWT, routeId, contractorId, documents, transactionId, sumBYN, sumUSD, sumEUR) {
  return fetch(`${url}/task/payment`, {
    method: 'POST',
    body: JSON.stringify({
      routeId: routeId,
      contractorId: contractorId,
      sumBYN: sumBYN,
      sumUSD: sumUSD,
      sumEUR: sumEUR,
      documents: documents,
      transactionId: transactionId
    }),
    headers: {
      "Content-Type": "application/json",
      "Authorization": `${userJWT}`,
    },
  }).then(checkResponse);

}

function confirmDelivery(userJWT, documents, comment) {

  return fetch(`${url}/task/confirmDelevery`, {
    method: 'POST',
    body: JSON.stringify({
      documents: documents,
      comment: comment,
    }),
    headers: {
      "Content-Type": "application/json",
      "Authorization": `${userJWT}`,
    },
  }).then(checkResponse);

}

function cancelDelivery(userJWT, documents, comment) {

  return fetch(`${url}/task/cancelDelevery`, {
    method: 'POST',
    body: JSON.stringify({
      documents: documents,
      comment: comment,
    }),
    headers: {
      "Content-Type": "application/json",
      "Authorization": `${userJWT}`,
    },
  }).then(checkResponse);

}

function getMyRoutes(userJWT) {
  return fetch(`${url}/routes`, {
    method: 'get',
    headers: {
      "Content-Type": "application/json",
      "Authorization": `${userJWT}`,
    },
  }).then(checkResponse);
}

function finishRoute(userJWT, routeId, mileage) {

  return fetch(`${url}/routes/finish`, {
    method: 'POST',
    body: JSON.stringify({
      routeId: routeId,
      mileage: mileage,
    }),
    headers: {
      "Content-Type": "application/json",
      "Authorization": `${userJWT}`,
    },
  }).then(checkResponse);

}

function startRoute(userJWT, routeId) {

  return fetch(`${url}/routes/start`, {
    method: 'POST',
    body: JSON.stringify({
      routeId: routeId,
    }),
    headers: {
      "Content-Type": "application/json",
      "Authorization": `${userJWT}`,
    },
  }).then(checkResponse);

}

function getBalance(userJWT) {
  return fetch(`${url}/cash/balance`, {
    method: 'GET',
    headers: {
      "Content-Type": "application/json",
      "Authorization": `${userJWT}`,
    },
  }).then(checkResponse);
}

function encashment(userJWT, sumBYN, sumEUR, sumUSD, sumZReport) {
  return fetch(`${url}/cash/encashment`, {
    method: 'POST',
    body: JSON.stringify({
      sumBYN: sumBYN,
      sumUSD: sumUSD,
      sumEUR: sumEUR,
      sumZReport: sumZReport,
    }),
    headers: {
      "Content-Type": "application/json",
      "Authorization": `${userJWT}`,
    },
  }).then(checkResponse);

}

function checkoutMovements(userJWT) {
  return fetch(`${url}/cash/checkoutMovements`, {
    method: 'POST',
    body: JSON.stringify({

    }),
    headers: {
      "Content-Type": "application/json",
      "Authorization": `${userJWT}`,
    },
  }).then(checkResponse);

}

function confirmReturns(userJWT, documents, comment) {

  return fetch(`${url}/task/confirmReturns`, {
    method: 'POST',
    body: JSON.stringify({
      documents: documents,
      comment: comment,
    }),
    headers: {
      "Content-Type": "application/json",
      "Authorization": `${userJWT}`,
    },
  }).then(checkResponse);
}


function cancelReturns(userJWT, documents, comment) {

  return fetch(`${url}/task/cancelReturns`, {
    method: 'POST',
    body: JSON.stringify({
      documents: documents,
      comment: comment,
    }),
    headers: {
      "Content-Type": "application/json",
      "Authorization": `${userJWT}`,
    },
  }).then(checkResponse);
}

function getTransactionDetails(userJWT, transactionId){
  return fetch(`${url}/task/paymentDetails/${transactionId}`, {
    method: 'GET',
    headers: {
      "Content-Type": "application/json",
      "Authorization": `${userJWT}`,
    },
  }).then(checkResponse);

}


export {
  getRoute,
  getAddressDetails,
  login,
  addPayment,
  confirmDelivery,
  getMyRoutes,
  finishRoute,
  startRoute,
  getBalance,
  encashment,
  cancelDelivery,
  checkoutMovements,
  confirmReturns,
  cancelReturns,
  getTransactionDetails
}