import { useState, useRef, useEffect } from 'react';
import './AddPayment.css';
import { useAuth } from './hook/useAuth';
import { addPayment, getTransactionDetails } from '../utils/Api';
import { useNavigate } from 'react-router-dom';

import InfoTooltip from './InfoTooltip';

function AddPayment({ contractorId, routeId, documents, addressId, handleDeliveryAddress, transactionId }) {
    const [sumBYN, setSumBYN] = useState('');
    const [sumUSD, setSumUSD] = useState('');
    const [sumEUR, setSumEUR] = useState('');

    const inputBYNref = useRef();

    const [querySended, setQuerySended] = useState(false);
    const [disableButton, setDisableButton] = useState(false);

    const [showInfo, setShowInfo] = useState(false);
    const [message, setMessage] = useState({ isError: false, text: '' });

    const authCtx = useAuth();
    const navigate = useNavigate();

    useEffect(()=>{
        inputBYNref.current.focus();
    },[])

    useEffect(()=>{
        getTransactionDetails(authCtx.token, transactionId)
        .then(data =>{
            setSumBYN(data.sumBYN);
            setSumEUR(data.sumEUR);
            setSumUSD(data.sumUSD);
        });
    }, []);

    function handleSubmit(evt) {
        evt.preventDefault();
        setDisableButton(true);

        if (Number(sumBYN) > 0 || Number(sumUSD) > 0 || Number(sumEUR) > 0) {
            addPayment(authCtx.token, routeId, contractorId, documents, transactionId, sumBYN, sumUSD, sumEUR)
                .then(data => {
                    handleDeliveryAddress(addressId);
                    setMessage({ isError: false, text: 'Оплата отправлена' });
                    setShowInfo(true);
                    setQuerySended(true);
                }).catch(err => {
                    setMessage({ isError: true, text: err });
                    setShowInfo(true);
                    setDisableButton(false);
                });
        } else {
            setMessage({ isError: true, text: 'Не указана сумма оплаты' });
            setShowInfo(true);
            setDisableButton(false);
        }

    }

    function handleChangeSummBYN(evt) {
        setSumBYN(evt.target.value);
        goToEndPage();
    }

    function goToEndPage() {
        const scrollHeight = Math.max(document.body.scrollHeight,
            document.documentElement.scrollHeight,
            document.body.offsetHeight,
            document.documentElement.offsetHeight,
            document.body.clientHeight,
            document.documentElement.clientHeight
        );

        window.scrollTo(0, scrollHeight);

    }

    function handleChangeSummUSD(evt) {
        setSumUSD(evt.target.value);
        goToEndPage();
    }

    function handleChangeSummEUR(evt) {
        setSumEUR(evt.target.value);
        goToEndPage();
    }

    function handleCloseInfoTooltip() {
        setShowInfo(false);

        if (querySended) {
            navigate('/');
        }
    }

    return (
        <form onSubmit={handleSubmit}>
            <h3>Ввод оплаты</h3>
            <div className='add-payment__field-wrapper'>
                <label className='add-payment__label' htmlFor="sumBYN">BYN</label>
                <input className='add-payment__input' type="number" id="sumBYN" min="0" max="10000" step="0.01" value={sumBYN} ref={inputBYNref} onChange={handleChangeSummBYN} />
                <label className='add-payment__label' htmlFor="sumUSD">USD</label>
                <input className='add-payment__input' type="number" id="sumUSD" min="0" max="10000" step="0.01" value={sumUSD} onChange={handleChangeSummUSD} />
                <label className='add-payment__label' htmlFor="sumEUR">EUR</label>
                <input className='add-payment__input' type="number" id="sumEUR" min="0" max="10000" step="0.01" value={sumEUR} onChange={handleChangeSummEUR} />
            </div>
            <button className="add-payment__submit" type="submit" disabled={disableButton}>Оплатить</button>

            {showInfo && <InfoTooltip message={message} onClose={handleCloseInfoTooltip} />}
        </form>
    )

}

export default AddPayment;