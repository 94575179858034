import Task from "./Task";
import { useState, useEffect } from 'react';
import './Tasks.css';

function Tasks({ route, handleDeliveryAddress }) {

    const [sortedRouts, setSortedRouts] = useState(route);
    const [filter, setFilter] = useState('');

    const [deliveryPoints, setDeliveryPoints] = useState([]);

    useEffect(() => {
        let points = route.map((item) => {
            return item.deliveryPoint;
        });

        points = new Set(points);
        points = [...points].sort();

        setDeliveryPoints(['Все', ...points]);
    }, [route])

    useEffect(() => {
        let sorted = route.sort((a, b) => {
            if (a.deliveryStatus === 1 && b.deliveryStatus === 1) {
                return 0;
            } else if (a.deliveryStatus === 1) {
                return 10 - b.deliveryStatus;
            } else if (b.deliveryStatus === 1) {
                return a.deliveryStatus - 10;
            }
            return a.deliveryStatus - b.deliveryStatus
        });

        if (filter && filter !== 'Все') {
            sorted = sorted.filter((item) => {
                return item.deliveryPoint === filter;
            });
        }

        setSortedRouts([...sorted]);
    }, [route, filter]);

    useEffect(() => {
        const curFilter = localStorage.getItem('filter');
        if(curFilter){
            setFilter(curFilter );
        }
        
    }, [])

    function handleFilterChange(evt) {
        console.log(evt.target.value);
        localStorage.setItem('filter', evt.target.value);
        setFilter(evt.target.value);
    }

    return (
        <>
            <div className="tasks__title">
                <h2>Маршрут</h2>
                <label className="tasks__filter-label">
                    Фильтр :
                    <select className="tasks__filter" value={filter} onChange={handleFilterChange} >
                        {deliveryPoints.map((item) => {
                            return (<option key={item} value={item} >{item}</option>)
                        })}

                    </select>
                </label>


            </div>
            {sortedRouts.length === 0 && <p>Нет рейса в состоянии "Выполняется"</p>}



            {sortedRouts.map((item) => {
                return (
                    <Task key={item.addressId}
                        contractor={item.contractor}
                        address={item.address}
                        addressId={item.addressId}
                        deliveryPoint={item.deliveryPoint}
                        id={item.id}
                        comment={item.comment}
                        deliveryStatus={item.deliveryStatus}
                        handleDeliveryAddress={handleDeliveryAddress}
                        commentToRoute={item.commentToRoute}
                    />
                )
            }
            )}
        </>
    )

}

export default Tasks;