import { useState, useEffect } from 'react';
import Tasks from './components/Tasks';
import './App.css';
import { getRoute } from './utils/Api';
import {
  Routes,
  Route
} from "react-router-dom";
import Cash from './components/Cash';
import SheduledFlights from './components/SheduledFlights';
import Payment from './components/Payment';
import Layout from './components/Layout';
import NotFoundPage from './components/NotFoundPage';
import Login from './components/Login';
import RequireAuth from './components/hoc/RequireAuth';
import { useAuth } from './components/hook/useAuth';
import CheckoutMovements from './components/CheckoutMovements';

function App() {
  const [route, setRoute] = useState([]);
  const [updateCounter, setUpdateCounter] = useState(0);

  const authCtx = useAuth();
  

  useEffect(() => {
    if (authCtx && authCtx.isLoggedIn) {
      getRoute(authCtx.token).then(data => {
        console.log('updateTasks');
        console.log(data);
        setRoute(data);
      });
    }

  }, [authCtx, updateCounter])

  function handleDeliveryAddress(addressId){
    setRoute((prevValue) => {
      const findedElement = prevValue.find(item =>{ return item.addressId === addressId });
      if(findedElement){
        findedElement.deliveryStatus = 5;
      }

      return [...prevValue];
    });
  }

  function handleChangeFlight(){
    setUpdateCounter(updateCounter + 1);
  }


  return (
   
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<RequireAuth><Tasks route={route} ></Tasks> </RequireAuth>} > </Route>
          <Route path="sheduled-flights" element={<RequireAuth><SheduledFlights onFlightChange={handleChangeFlight}></SheduledFlights></RequireAuth>} />
          <Route path="checkout-movements" element={<RequireAuth><CheckoutMovements></CheckoutMovements></RequireAuth>} />
          <Route path="cash" element={<RequireAuth><Cash></Cash></RequireAuth>} />
          <Route path="payment/:id" element={<RequireAuth><Payment handleDeliveryAddress={handleDeliveryAddress}></Payment></RequireAuth>} />
          <Route path="login" element={<Login></Login>} />
          <Route path="*" element={<NotFoundPage />} />
        </Route>
      </Routes>
    
  );
}

export default App;
