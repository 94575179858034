import {useAuth} from './hook/useAuth'
import {useEffect, useState} from 'react';
import {getMyRoutes} from '../utils/Api';
import Flight from './Flight';

function SheduledFlights({onFlightChange}){
    const authCtx = useAuth();
    const [flights, setFlights] = useState([]);
    const [version, setVersion] = useState(0);
    
    useEffect(()=>{
        getMyRoutes(authCtx.token)
            .then(data =>{
                setFlights(data);
            }).catch(err =>{

            });
    }, [authCtx, version]);

    function handleChangeFlight(){
        setVersion(version + 1);
        onFlightChange();
    }


    const handleExitClick = (evt)=>{
        authCtx.signout();
    }

    return(
        <>
            <h2>Доступные рейсы</h2>
            {flights.length === 0 && <p>Нет доступных рейсов</p>}
            {flights.map((item) =>{
                return (<Flight key={item.id} {...item} onChange={handleChangeFlight} ></Flight>);
            })}

            <div><button onClick={handleExitClick}>Выйти</button></div>
        </>
        );


}

export default SheduledFlights