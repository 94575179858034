import {useNavigate} from 'react-router-dom'

function NotFoundPage(){
    const navigate = useNavigate();

    const goBack =() =>{
        navigate(-1);
    }

    return(
        <>
        <h2>404 страница не найдена</h2>
        <button onClick={goBack}>Назад</button>
        </>
    )

}

export default NotFoundPage;