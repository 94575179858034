import { useEffect, useState } from 'react';
import { useAuth } from './hook/useAuth';
import { getBalance } from '../utils/Api';
import './Cash.css';
import ClosingCashRegister from './ClosingCashRegister';
import {NavLink} from 'react-router-dom';
import Button from './Button';



function Cash() {
    const [balance, setBalance] = useState({ sumBYN: '', sumEUR: '', sumUSD: '', sumRUR: '' });
    const [showClosingCashRegister, setShowClosingCashRegister] = useState(false);
    const authCtx = useAuth();

    useEffect(() => {
        getBalance(authCtx.token)
            .then(data => {
                console.log(data);
                setBalance(data);
            }).catch(() =>{
                setBalance({ sumBYN: 'Ошибка получения данных...', sumEUR: 'Ошибка получения данных...', sumUSD: 'Ошибка получения данных...', sumRUR: 'Ошибка получения данных...' })
            });

    }, [authCtx]);

    return (
        <>
            <h2>Касса</h2>
            <div className='cash__balance'>
                <div className='cash__balance-cell'>BYN :</div>
                <div className='cash__balance-cell'>{balance.sumBYN}</div>
                <div className='cash__balance-cell'>USD :</div>
                <div className='cash__balance-cell'>{balance.sumUSD}</div>
                <div className='cash__balance-cell'>EUR :</div>
                <div className='cash__balance-cell'>{balance.sumEUR}</div>
            </div>

            <div className='cash__actions'>
                <NavLink className='cash__button' to='/checkout-movements'>Реестр оплат</NavLink>
                <button className='cash__button cash__button_danger' onClick={()=>{setShowClosingCashRegister(true)}} >Закрыть кассу</button>
            </div>
            

            {showClosingCashRegister && <ClosingCashRegister sumBYN={balance.sumBYN} sumUSD={balance.sumUSD} sumEUR={balance.sumEUR}/>}
        </>

    )
}

export default Cash;