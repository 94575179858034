import { useRef } from 'react';
import './InfoTooltip.css';
import successImage from '../images/success.svg';
import errorImage from '../images/error.svg';
function InfoTooltip(props) {
  const backdropRef = useRef();

  function handleBackdropClick(evt){
    if(evt.target === backdropRef.current){
      props.onClose();
    }    
  }

  return (

    <div className="infotooltip__backdrop" ref={backdropRef} onClick={handleBackdropClick}>
      <div className='infotooltip'>
        <img
          className='infotooltip__icon'
          src={props.message.isError ? errorImage : successImage}
          alt=''
        />
        <p className='infotooltip__text'>{props.message.text}</p>
        <button
          className='infotooltip__close-button'
          type='button'
          aria-label='Закрыть'
          onClick={props.onClose}
        ></button>
      </div>
    </div>
  );
}

export default InfoTooltip;