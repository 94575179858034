import './Navigation.css';
import { Link, NavLink } from 'react-router-dom';

function Navigation() {
    return (
        <nav className='navigation'>
            <ul className='navigation__list'>
                <li className="navigation__list-item"><NavLink className='navigation__item navigation__item_route' to="/"></NavLink></li>
                <li className="navigation__list-item"><NavLink className='navigation__item navigation__item_route-list' to="/sheduled-flights"></NavLink></li>
                <li className="navigation__list-item"><NavLink className='navigation__item navigation__item_cash' to="/cash"></NavLink></li>
            </ul>
        </nav>
    )

}

export default Navigation;