import './ClosingCashRegister.css';
import Button from './Button';
import {useState} from 'react';
import {useAuth} from './hook/useAuth';
import {encashment} from '../utils/Api';
import InfoTooltip from './InfoTooltip';
import { useNavigate } from 'react-router-dom';
import Input from './UI/Input';

function ClosingCashRegister({sumBYN, sumUSD, sumEUR}){

    const [sumZReport, setSumZReport] = useState(''); 
    const authCtx = useAuth();

    const [querySended, setQuerySended] = useState(false);
    const [disableButton, setDisableButton] = useState(false);
    const [showInfo, setShowInfo] = useState(false);
    const [message, setMessage] = useState({ isError: false, text: '' });
    const navigate = useNavigate();

    function handleSubmit(evt){
        evt.preventDefault();
        setDisableButton(true);
        encashment(authCtx.token, sumBYN, sumEUR, sumUSD, sumZReport)
        .then(data =>{
            setMessage({ isError: false, text: 'Касса закрылась' });
            setShowInfo(true);
            setQuerySended(true);
            
        })
        .catch(error =>{
            setMessage({ isError: true, text: 'Ошибка при закрытии кассы ' + error });
            setShowInfo(true);
            setDisableButton(false);
        });
        
    }

    function handleSumZReportChange(evt){
        setSumZReport(evt.target.value);
    }

    function handleCloseInfoTooltip() {
        setShowInfo(false);

        if (querySended) {
            navigate('/cash');
        }
    }

    return (
        <>
        
        <form onSubmit={handleSubmit}>
            <h3>Закрытие кассы</h3>
    
            <Input className = 'closing-cash-register__summ-z-report' type="number" id="sumZReport" value={sumZReport} onChange={handleSumZReportChange} labelText='Пробито по кассе : ' />
           <div className="closing-cash-register__submit">
                <Button onClick={handleSubmit} disabled={disableButton}>Закрыть кассу</Button>
           </div>
            
        </form>
        {showInfo && <InfoTooltip message={message} onClose={handleCloseInfoTooltip} />}
        
        </>
    )

}

export default ClosingCashRegister