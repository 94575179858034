import './Task.css';
import { Link } from 'react-router-dom';

function Task({ contractor, address, deliveryPoint, id, comment, addressId, deliveryStatus, handleDeliveryAddress, commentToRoute}) {
    return (
        <div className="task">
            
            <Link className={`task__link ${ deliveryStatus >=5 || deliveryStatus === 1  ? 'task__link_stricted' : ''} ` } to={'/payment/' + addressId}>
                <div className="task__contractor">{contractor}</div>
                <div className="task__address">{address}</div>
                
                <div className="task__delivery-point">{deliveryPoint}</div>
                <div className="task__comment">{commentToRoute}</div>
            </Link>

        </div>
    );

}

export default Task;