import './ContractInfo.css';

function ContractInfo({name, debt}){
    return (
        <div className='contract-info'>
            <div>{name} : </div>
            <div>{debt}</div>
        </div>
    );

}

export default ContractInfo;