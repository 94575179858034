import './ManagerPhones.css';
import {useState} from 'react';
function ManagerPhones({manager}){
    const [showDetails, setShowDetails] = useState(false);

    function parsePhone(str) {
        let phone = '';

        if(!str){
            return phone;
        }

        const regex = /(\+\d{11})\w+/gim;
        let formatPhones = str.match(regex);
        if (Array.isArray(formatPhones)) {
            formatPhones.forEach(item => {
                phone = item;
            });
        }

        // Телефон в формате +375 (33) 999-99-99
        const regex2 = /\+\d{3} \(\d{2}\) \d{3}-\d{2}-\d{2}/gim
        if (regex2.test(str)) {
            let nonFormatPhones = str.match(regex2);
            for (let i = 0; i < nonFormatPhones.length; i++) {
                let nonFormatPhone = nonFormatPhones[i];
                nonFormatPhone = nonFormatPhone.replace(/\(| |\)|\-/gim, '');
                phone = nonFormatPhone;
            }
        }

        // Телефон в формате +375 29 9999999
        const regex3 = /\+\d{3} \d{2} \d{3}\d{2}\d{2}/gim
        if (regex3.test(str)) {
            let nonFormatPhones = str.match(regex3);
            for (let i = 0; i < nonFormatPhones.length; i++) {
                let nonFormatPhone = nonFormatPhones[i];
                nonFormatPhone = nonFormatPhone.replace(/\(| |\)|\-/gim, '');
                phone = nonFormatPhone;
            }
        }

         // Телефон в формате +375 29 999 99 99
        const regex4 = /\+\d{3} \d{2} \d{3} \d{2} \d{2}/gim
        if (regex4.test(str)) {
        let nonFormatPhones = str.match(regex4);
        for (let i = 0; i < nonFormatPhones.length; i++) {
            let nonFormatPhone = nonFormatPhones[i];
            nonFormatPhone = nonFormatPhone.replace(/\(| |\)|\-/gim, '');
            phone = nonFormatPhone;
        }
    }

        return phone;
    }

    return(
        <>
            <h4 className='manager-phones__title' onClick={ ()=>{setShowDetails((prevValue) => ! prevValue)}} >Контакты менеджера >>></h4>
            {showDetails && manager && <div className='manager-phones__name'>{manager.name}</div> }
            {showDetails && manager && manager.phones.map(item =>{
                return (<div key={item.type}> 
                    <div className='manager-phones__phone-type'>{item.type}</div>
                    <a className='manager-phones__phone' href={`tel:${parsePhone(item.phone)}`}><span className='manager-phones__phone-icon' />{item.phone}</a> 
                </div>)
            })}
            
        </>

    )

}

export default ManagerPhones;