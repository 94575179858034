import {useLocation, Navigate} from 'react-router-dom';
import {useAuth} from '../hook/useAuth';

function RequireAuth(props){
    const location = useLocation();
    const {user, isLoggedIn} = useAuth();

    if(!isLoggedIn){
        return <Navigate to='/login' state={{from: location}} ></Navigate>
    }

    return(
        props.children
    );
}

export default RequireAuth