import {createContext, useState, useEffect} from 'react';


export const AuthContext = createContext(null);

export const AuthProvider = ({children}) =>{
    const initialToken = localStorage.getItem('token');    

    const [user, setUser] = useState(null);
    const [token, setToken] = useState(initialToken);
    const [isLoggedIn, setIsLoggedIn] = useState(!!token);

    
    const signin = (newUser, jwt, cb) =>{
        localStorage.setItem('token', jwt);
        setUser(newUser);
        setToken(jwt);
        setIsLoggedIn(true);
        cb();
    }

    const signout = (cb) => {
        setUser(null);
        setToken('');
        setIsLoggedIn(false);
        cb();
    }

    const value = {user, signin, signout, isLoggedIn, token}

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    )
}