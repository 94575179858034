import { useEffect, useState } from 'react';
import { useAuth } from './hook/useAuth';
import { checkoutMovements } from '../utils/Api';
import './CheckoutMovements.css';
import Preloader from './Preloader';

function CheckoutMovements() {
    const [data, setData] = useState([]);
    const [showPreloader, setShowPreloader] = useState(false);

    const authCtx = useAuth();

    useEffect(() => {
        setShowPreloader(true);
        checkoutMovements(authCtx.token)
            .then(data => {
                setData(data);
                console.log(data);
            })
            .catch(error => { console.log(error) })
            .finally(()=>{
                setShowPreloader(false);
            });
            
    }, []);


    return (
        <>
            <h1>Движения по кассе</h1>
            {showPreloader && <Preloader></Preloader>}
            {data.map((cashregister) => {
                return (
                    <div key={cashregister.cashRegister}>
                        <div className='checkout-movements__cash-register' >
                            <div className='checkout-movements__cash-register-name'>{cashregister.cashRegister}</div>

                            {cashregister.payments.map((payment) => {
                                return (<div className='checkout-movements__payment'>

                                    <div >
                                        <div className='checkout-movements__contractor'>{payment.contractor}</div>
                                        <div className='checkout-movements__document'>{payment.document}</div>
                                    </div>
                                    <div className='checkout-movements__sum'>{payment.sum}</div>
                                    <div>{payment.curency}</div>


                                </div>)
                            })}
                            <div className='checkout-movements__payment'>
                                <div className="checkout-movements__total">ИТОГО:</div>
                                <div className="checkout-movements__sum checkout-movements__total">{cashregister.total}</div>
                                <div className="">{cashregister.curency}</div>
                            </div>
                        </div>
                        
                    </div>
                )
            })}
        </>
    )

}

export default CheckoutMovements