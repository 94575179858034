import styles from './Input.module.css';

function Input({type, id, value, onChange, labelText}){
    console.log(styles.label);
    console.log(styles.input);
    return (
        <>
            <label className={styles.label} htmlFor={id}>{labelText}</label>
            <input
                className={styles.input} 
                type={type}
                id={id} 
                value={value}
                onChange={onChange}
            />
        </>
        
    )
}

export default Input;