import { getDate } from '../utils/StringOperations';
import './Returns.css';
import { useState, useRef } from 'react';
import { useAuth } from './hook/useAuth';
import { confirmReturns, cancelReturns } from '../utils/Api';
import InfoTooltip from './InfoTooltip';



function Returns({ documents, onStatusChange}) {
    const [showInfo, setShowInfo] = useState(false);
    const [message, setMessage] = useState({isError: false, text:''});

    const [disableButton, setDisableButton] = useState(false);

    const commentInputRef = useRef();

    const authCtx = useAuth();

    function handleConfirmClick(ent) {
        const comment = commentInputRef.current.value;
        setDisableButton(true);

        

        
        confirmReturns(authCtx.token, documents, comment)
            .then((data) => {
                setMessage({ isError: false, text: 'Возврат принят'});
                setShowInfo(true); 
                onStatusChange(5);
                console.log(data);
            })
            .catch(error => { 
                setMessage({ isError: true, text: error});
                setShowInfo(true); 
                setDisableButton(false);
            });

    }

    function handleCancelClick() {
        const comment = commentInputRef.current.value;
        setDisableButton(true);

        if(! comment){
            setMessage({ isError: true, text: 'Обязательно укажите причину незабора возврата'});
            setShowInfo(true); 
            setDisableButton(false);
            return;
        }

        cancelReturns(authCtx.token, documents, comment)
            .then((data) => {
                setMessage({ isError: false, text: 'Возврат не принят'});
                setShowInfo(true); 
                onStatusChange(1);
                console.log(data);
            })
            .catch(error => {
                console.log(error);
            });
    }

    function handleCloseInfoTooltip(){
        setShowInfo(false);
    }

    console.log(documents);

    return (
        <div className='returns'>
            <h3 className='returns__title'>Забрать возвраты</h3>
            {documents.map(item => {
                return (
                    <div key={item.id} className={`returns__document ${item.deliveryStatus >= 5 || item.deliveryStatus === 1 ? 'returns__document_stricted' : ''}`} >
                        {item.typeOfDocument} {item.num1C} от {getDate(item.date)}
                    </div>
                )
            })}

            <div className='returns__actions'>
                <div className="">
                    <input className='returns__comment' name='returnComment' ref={commentInputRef} placeholder='Комментарий к возвратам' />
                </div>

                <div className="returns__action-buttons">
                    <button className='returns__button returns__button_ok' onClick={handleConfirmClick} disabled={disableButton} >
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="50px" height="50px"><path d="M 25 2 C 12.309534 2 2 12.309534 2 25 C 2 37.690466 12.309534 48 25 48 C 37.690466 48 48 37.690466 48 25 C 48 12.309534 37.690466 2 25 2 z M 25 4 C 36.609534 4 46 13.390466 46 25 C 46 36.609534 36.609534 46 25 46 C 13.390466 46 4 36.609534 4 25 C 4 13.390466 13.390466 4 25 4 z M 34.988281 14.988281 A 1.0001 1.0001 0 0 0 34.171875 15.439453 L 23.970703 30.476562 L 16.679688 23.710938 A 1.0001 1.0001 0 1 0 15.320312 25.177734 L 24.316406 33.525391 L 35.828125 16.560547 A 1.0001 1.0001 0 0 0 34.988281 14.988281 z" /></svg>
                    </button>
                    <button className='returns__button returns__button_cancel' onClick={handleCancelClick} disabled={disableButton} >
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="50px" height="50px"><path d="M 25 2 C 12.309534 2 2 12.309534 2 25 C 2 37.690466 12.309534 48 25 48 C 37.690466 48 48 37.690466 48 25 C 48 12.309534 37.690466 2 25 2 z M 25 4 C 36.609534 4 46 13.390466 46 25 C 46 36.609534 36.609534 46 25 46 C 13.390466 46 4 36.609534 4 25 C 4 13.390466 13.390466 4 25 4 z M 32.990234 15.986328 A 1.0001 1.0001 0 0 0 32.292969 16.292969 L 25 23.585938 L 17.707031 16.292969 A 1.0001 1.0001 0 0 0 16.990234 15.990234 A 1.0001 1.0001 0 0 0 16.292969 17.707031 L 23.585938 25 L 16.292969 32.292969 A 1.0001 1.0001 0 1 0 17.707031 33.707031 L 25 26.414062 L 32.292969 33.707031 A 1.0001 1.0001 0 1 0 33.707031 32.292969 L 26.414062 25 L 33.707031 17.707031 A 1.0001 1.0001 0 0 0 32.990234 15.986328 z" /></svg>
                    </button>

                </div>
            </div>
            { showInfo && <InfoTooltip message={message} onClose={handleCloseInfoTooltip }/>}
        </div >
    )
}

export default Returns