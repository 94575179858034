import { useState, useRef } from 'react';
import {cancelDelivery} from '../utils/Api';
import {useAuth} from './hook/useAuth';
import { useNavigate } from 'react-router-dom';
import InfoTooltip from './InfoTooltip';
import './CancelDelivery.css';

function ConfirmDelivery({documents, addressId, handleDeliveryAddress}){
    const [querySended, setQuerySended] = useState(false);
    const [showInfo, setShowInfo] = useState(false);
    const [message, setMessage] = useState({isError: false, text:''});

    const [disableButton, setDisableButton] = useState(false);

    const navigate = useNavigate();
    const authCtx = useAuth();

    const commentInputRef = useRef();

    function handleSubmit(evt){
        evt.preventDefault();
        setDisableButton(true);

        const userComment = commentInputRef.current.value;
        
        let docs = documents.map(item => {
            return {id: item.id}
        });

        if(! userComment){
            setMessage({ isError: true, text: 'Обязательно укажите комментарий'});
            setShowInfo(true); 
            setDisableButton(false);
            return;
        }

        cancelDelivery(authCtx.token, docs, userComment)            
            .then(data =>{
                handleDeliveryAddress(addressId);
                setMessage({ isError: false, text: 'Отмена доставки отправлена'});
                setShowInfo(true); 
                setQuerySended(true);
            })
            .catch(error =>{
                setMessage({ isError: true, text: error});
                setShowInfo(true); 
                setDisableButton(false);
            }).finally(()=>{
                
            })       
    }

    function handleCloseInfoTooltip(){
        setShowInfo(false);

        if(querySended){
            navigate('/');
        }
    }

    return(
        <form className='cancel-delivery' onSubmit={handleSubmit}>
            <h3>Отмена доставки</h3>
            <textarea className='cancel-delivery__comment' ref={commentInputRef} placeholder='Введите текст комментария...'></textarea>
            <button className='cancel-delivery__submit' type="submit" disabled={disableButton}>Отменить доставку</button>
            { showInfo && <InfoTooltip message={message} onClose={handleCloseInfoTooltip }/>}
        </form>
    )
}

export default ConfirmDelivery;