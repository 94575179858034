import { Outlet } from 'react-router-dom'
import Navigation from './Navigation';
import './Layout.css';


function Layout() {
    return (
        <>
            <main className='content'>
                <Outlet></Outlet>
            </main>

            <Navigation></Navigation>
        </>
    )


}

export default Layout;