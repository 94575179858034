import { useState } from 'react';
import './Document.css';
import Date from './Date';


function Document({ typeOfDocument, num1C, date, numberOfCargoPlaces, summ, comment, routNumber, routDate }) {
 
    return (
        <div className='document' >
            <div className='document__title document__cell'>{typeOfDocument} {num1C} от <Date date={date}/> </div>
            <div className='document__cell'>Груз мест :</div>
            <div className='document__cell document__cell_number'> {numberOfCargoPlaces}</div>
            <div className='document__cell'>Сумма : </div>
            <div className='document__cell document__cell_number'>{summ}</div>
            <div className='document__comment document__cell'>
                <div className='document__route-info'>Рейс: {routNumber} от <Date date={routDate} /> </div> 
                {comment}
            </div>
        </div>
    )

}

export default Document;