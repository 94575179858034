import {getDate} from '../utils/StringOperations';

function Date({date}){
    return (
        <>
            {getDate(date)}
        </>        
    )
}

export default Date;