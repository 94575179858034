import { useState, useRef } from 'react';
import './Flight.css';
import { getDate } from '../utils/StringOperations';
import { useAuth } from './hook/useAuth';
import { finishRoute, startRoute } from '../utils/Api';
import InfoTooltip from './InfoTooltip';

function Flight({ num1C, routeDirection, id, date, status, car, onChange }) {

    const [showComplete, setShowComplete] = useState(false);
    const [showStartFlight, setStartFlight] = useState(false);
    const [showInfo, setShowInfo] = useState(false);
    const [message, setMessage] = useState({ isError: false, text: '' });
    const mileageInputRef = useRef();

    const authCtx = useAuth();

    function clickHandler(evt) {
        if (status === 4) {
            //setShowComplete((prevValue) => {return !prevValue});
            setShowComplete(true);
        } else if (status === 3) {
            //setStartFlight((prevValue) => {return !prevValue});
            setStartFlight(true);
        }
    }

    function handleCompleteFlight(evt) {
        evt.preventDefault();
        const mileage = mileageInputRef.current.value;

        if( Number(mileage) === 0){
            setMessage({ isError: true, text: 'Заполните сколько проехали километров' });

            setShowInfo(true);
            return;
        }

        finishRoute(authCtx.token, id, mileage).then((data) => {
            setMessage({ isError: false, text: 'Рейс завершен' });
            setShowInfo(true);
            localStorage.setItem('filter', 'Все');
            onChange();
        }).catch(error => {
            setMessage({ isError: true, text: error });
            setShowInfo(true);
        });

    }

    function handleStartFlight(evt) {
        evt.preventDefault();

        startRoute(authCtx.token, id).then((data) => {
            setMessage({ isError: false, text: 'Рейс переведен в статус "В пути"' });
            setShowInfo(true);
            onChange();

        }).catch(error => {
            setMessage({ isError: true, text: error });
            setShowInfo(true);
        });
    }

    function handleCloseInfoTooltip() {
        setShowInfo(false);
    }

    function CompleteFlight() {
        return (
            <form className='flight__form' onSubmit={handleCompleteFlight}>
                <h2>Завершение рейса</h2>
                <label htmlFor="mileage">Сколько проехали километров? </label>
                <input className='flight__input' id="mileage" type="number" min="1" max="10000" ref={mileageInputRef} />
                <div>
                    <button className='flight__submit'>Завершить рейс</button>
                </div>

            </form>
        );
    }

    function StartFlight() {
        return (
            <form className='flight__form' onSubmit={handleStartFlight}>
                <h2>Начало выполнения рейса</h2>
                <button className='flight__submit'>Начать выполение рейса</button>
            </form>
        );
    }

    function getStatusDescription(status) {
        if (status === 3) {
            return 'Утвержден';
        } else if (status === 4) {
            return 'В пути';
        } else {
            return 'Неизвестный статус';
        }

    }

    return (<div className='flight' onClick={clickHandler}>
        <div>{num1C} от {getDate(date)}</div>
        <div>{getStatusDescription(status)}</div>
        <div>{routeDirection}</div>
        <div>{car}</div>
        {showComplete && CompleteFlight()}
        {showStartFlight && StartFlight()}
        {showInfo && <InfoTooltip message={message} onClose={handleCloseInfoTooltip} />}
    </div>);

}

export default Flight;